<template>
  <div class="travel_type-list" :key="key">
    <div class="text-h5 font-weight-bold mb-5">Inspirations</div>
    <draggable :list="data" @change="changeOrder($event)" handle=".handle">
      <div v-for="item in data" :key="item.id" class="d-flex align-center mt-3">
        <img class="move-icon handle me-3" src="@/assets/icon/move.svg" alt="icon" />
        <v-card outlined flat class="width100 rounded-lg py-4 px-5 d-flex align-center">
          <v-text-field
            class="field46 width100 mt-md-6 me-md-3"
            v-model="item.hashtag"
            placeholder="Enter hashtag"
            :error-messages="hashtagErrors"
            prefix="#"
            outlined
            dense
            required
            color="primary"
            height="46"
          ></v-text-field>
          <div class="gray--text text-capitalize d-flex ml-10"><v-icon class="mr-2 gray--text">mdi-instagram</v-icon>{{ item.type }}</div>
          <v-img
            class="rounded-lg mx-10 card-img link d-flex align-center justify-center"
            @click="changeImage(item)"
            max-width="100"
            height="100"
            cover
            :src="item.image || item.picture"
          >
            <div class="block text-center mx-auto">
              <img width="36" src="@/assets/icon/upload.svg" alt="icon" />
              <div class="text-body-2 white--text">Replace<br />Image</div>
            </div>
          </v-img>
          <div class="width100">
            <v-btn @click="update(item)" depressed outlined color="primary" class="rounded mr-2" width="100" height="38">
              <v-icon color="primary">mdi-check</v-icon><span class="text-body-2">Save</span>
            </v-btn>
            <v-btn @click="openRemove(item)" depressed outlined color="error" class="rounded" width="48" height="38" icon>
              <img class="error-filter" src="@/assets/icon/delete.svg" alt="icon" />
            </v-btn>
          </div>
        </v-card>
      </div>
    </draggable>
    <v-btn @click="modal = true" depressed class="primary rounded mt-5 ml-6" width="136" height="30">
      <span class="text-body-2">Add new</span>
    </v-btn>
    <v-dialog v-model="modal" max-width="700">
      <v-card flat>
        <div class="font-weight-bold py-4 text-center">New Inspiration</div>
        <v-card flat outlined class="px-10 py-3">
          <v-card flat height="290" width="290" class="mx-auto gray darken-5 d-flex align-center justify-center rounded">
            <div class="white--text text-center link" @click="changeImage('')" v-if="!image">
              <img src="@/assets/icon/upload.svg" alt="icon" />
              <div class="font-weight-bold">Upload image</div>
              <div class="text-body-2">.jpeg</div>
            </div>
            <div v-else class="rounded width100 link" @click="changeImage('')">
              <img class="bg-picture width100 d-block rounded" :src="image" />
              <div class="bg-picture-btn white--text text-center align-center justify-center">
                <div>
                  <img src="@/assets/icon/upload.svg" alt="icon" />
                  <div class="font-weight-bold">Upload image</div>
                  <div class="text-body-2 mb-3">.jpeg</div>
                </div>
              </div>
            </div>
          </v-card>
          <v-card flat min-height="20" v-if="!modalData.id" class="error--text text-center text-caption mb-5">
            {{ pictureErrors.join('') }}
          </v-card>
          <v-row>
            <v-col cols="6" class="py-0">
              <v-textarea
                class="field46"
                :error-messages="hashtagErrors"
                v-model="modalData.hashtag"
                placeholder="Enter hashtag"
                prefix="#"
                outlined
                dense
                required
                color="primary"
                auto-grow
                rows="1"
                row-height="38"
              ></v-textarea>
            </v-col>
            <v-col cols="6" class="py-0">
              <v-select
                class="field46"
                v-model="modalData.type"
                :error-messages="typeErrors"
                placeholder="Enter type"
                :items="['instagram']"
                outlined
                dense
                required
                color="primary"
                height="46"
              >
                <template v-slot:selection="data">
                  <span class="text-capitalize">{{ data.item }}</span>
                </template>
                <template v-slot:item="data">
                  <span class="text-capitalize">{{ data.item }}</span>
                </template>
              </v-select>
            </v-col>
          </v-row>
        </v-card>
        <div class="text-end py-5 px-10">
          <v-btn @click="modal = false" depressed class="me-3 rounded" outlined width="106" height="30" text color="gray lighten-1">
            <span class="black--text text-body-2">Cancel</span>
          </v-btn>
          <v-btn @click="createItem" depressed class="primary rounded text-body-2" width="106" height="30">Done</v-btn>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="modalRemove" max-width="480">
      <v-card flat color="pa-4">
        <div class="text-h6 font-weight-bold">Do you really want to remove {{ modalData.hashtag }}?</div>
        <div class="text-end mb-1 mt-5">
          <v-btn @click="modalRemove = false" depressed class="me-3 rounded" outlined width="106" height="30" text color="gray lighten-1">
            <span class="black--text text-body-2">Cancel</span>
          </v-btn>
          <v-btn @click="removeItem" depressed class="error rounded text-body-2" width="106" height="30">Remove</v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import draggable from 'vuedraggable';

export default {
  components: {
    draggable,
  },
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      modal: false,
      modalRemove: false,
      modalData: {},
      image: '',
      file: '',
      key: 1,
      error: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    notifi(btn) {
      if (btn == 'done') {
        this.$notify({
          title: 'Done!',
          message: 'Inspiration item was successfully update',
          type: 'success',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == 'photo') {
        this.$notify({
          title: 'Done!',
          message: 'Inspiration picture item was successfully update',
          type: 'success',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == '500') {
        this.$notify({
          title: 'Something went wrong!',
          message: 'Sorry, something went wrong, please contact support',
          type: 'error',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      }
    },
    changeImage(i) {
      const input = document.createElement('input');
      const self = this;
      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'image/*');
      input.addEventListener('change', function(e) {
        if (this.files && this.files[0]) {
          i ? (i.file = this.files[0]) : (self.file = this.files[0]);
          var reader = new FileReader();
          reader.onload = (e) => {
            i ? (i.image = e.target.result) : (self.image = e.target.result);
            i ? self.updatePicture(i) : '';
          };
          reader.readAsDataURL(this.files[0]);
        }
      });
      input.click();
    },
    openRemove(i) {
      this.modalRemove = true;
      this.$nextTick(() => {
        this.modalData = Object.assign({}, i);
      });
    },
    async changeOrder(e) {
      this.error = [];
      const data = new Object();
      data.order = e.moved.newIndex;
      await this.$store.dispatch('updateInspirationsItemOrder', { id: e.moved.element.id, data: data }).catch((e) => {
        this.error = e.response.data.error;
      });
    },
    async getData() {
      await this.$store.dispatch('getInspirationsList').catch((e) => {
        this.getDataError(e);
      });
    },
    getDataError(e) {
      this.statusError = e.response.status;
      if (this.statusError >= 500) {
        this.notifi('500');
      }
    },
    async createItem() {
      this.error = [];
      const formData = new FormData();
      formData.append('picture', this.file);
      this.modalData.type ? formData.append('type', this.modalData.type) : '';
      this.modalData.hashtag ? formData.append('hashtag', this.modalData.hashtag) : '';
      await this.$store
        .dispatch('createInspirations', formData)
        .then((res) => {
          this.modal = false;
          this.modalData = {};
          this.image = '';
          this.file = '';
          this.data.push(res.data);
        })
        .catch((e) => {
          this.error = e.response.data.error;
        });
    },
    async update(item) {
      this.error = [];
      const data = new Object();
      data.type = item.type || '';
      data.hashtag = item.hashtag || '';
      await this.$store
        .dispatch('updateInspirationsItem', { id: item.id, data: data })
        .then(() => {
          this.notifi('done');
        })
        .catch((e) => {
          this.error = e.response.data.error;
          this.modal ? '' : this.notifi('500');
        });
    },
    async updatePicture(item) {
      this.error = [];
      const formData = new FormData();
      formData.append('picture', item.file);
      await this.$store
        .dispatch('updateInspirationsItemPicture', { id: item.id, data: formData })
        .then(() => {
          this.notifi('photo');
        })
        .catch((e) => {
          this.error = e.response.data.error;
        });
    },
    async removeItem() {
      this.error = [];
      await this.$store
        .dispatch('removeInspirationsItem', this.modalData.id)
        .then(() => {
          const idx = this.data.findIndex((i) => i.id == this.modalData.id);
          this.modalRemove = false;
          this.data.splice(idx, 1);
          ++this.key;
          this.modalData = {};
        })
        .catch((e) => {
          this.error = e.response.data.error;
          this.modal ? '' : this.notifi('500');
        });
    },
  },
  watch: {
    modal() {
      this.error = [];
      this.modalData = {};
      this.image = '';
      this.file = '';
    },
  },
  computed: {
    data() {
      return this.$store.getters.inspirationsList;
    },
    typeErrors() {
      const errors = [];
      this.error.find((item) => item == 'type__required') && errors.push('Please enter type');
      this.error.find((item) => item == 'type__invalid') && errors.push('Provided type is not valid');
      return errors;
    },
    hashtagErrors() {
      const errors = [];
      this.error.find((item) => item == 'hashtag__required') && errors.push('Please enter hashtag');
      this.error.find((item) => item == 'hashtag__invalid') && errors.push('Provided hashtag is not valid');
      return errors;
    },
    pictureErrors() {
      const errors = [];
      this.error.find((item) => item == 'picture__required') && errors.push('Please enter picture');
      return errors;
    },
  },
  destroyed() {
    this.$store.dispatch('setInspirationsList');
  },
};
</script>

<style lang="scss">
.bg-picture {
  object-fit: cover;
}
.bg-picture-btn {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 290px;
  background: rgba($color: #3d3d3d, $alpha: 0.6);
  z-index: 9;
}
.rounded:hover {
  .bg-picture-btn {
    display: flex;
  }
}
#app .travel_type-list {
  .v-input {
    max-width: 395px;
  }
  .block {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100px;
    background: rgba($color: #3d3d3d, $alpha: 0.6);
  }
  .card-img:hover {
    .block {
      padding-top: 8px;
      display: block;
    }
  }
  .v-btn.primary--text {
    background: var(--v-primary-lighten3);
  }
  .v-btn.error--text {
    background: var(--v-error-lighten5);
  }
}
</style>
